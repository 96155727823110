import 'reset-css';
import '../scss/common.scss';
import Micromodal from 'micromodal';
import axios from 'axios';

function initScrollSpy() {
    const navbar = document.querySelector('.navbar');
    let isFixed = false;
    const handler = () => {
        if (document.documentElement.scrollTop > 80) {
            if (!isFixed) {
                navbar.classList.add('show-logo');
                isFixed = true;
            }
            return;
        }
        if (isFixed) {
            navbar.classList.remove('show-logo');
            isFixed = false;
        }
    };
    window.addEventListener('scroll', handler);

    handler();
    return handler;
}

function handleFormSubmit() {
    const form = document.querySelector('#contact-form');
    let submitting = false;
    form.addEventListener('submit', (e) => {
        e.preventDefault();
        if (submitting) {
            return false;
        }
        submitting = true;
        const data = Array.from(form.elements).reduce((acc, curr) => {
            if (curr.value) {
                acc[curr.name] = curr.value;
            }
            return acc;
        }, {});

        axios
            .post(form.getAttribute('action'), data)
            .then((resp) => {
                const h = document.querySelector('#contact-modal-title');
                const sh = document.querySelector('.modal__header__subheader');
                sh.parentNode.removeChild(sh);
                h.textContent = resp.data.message;
                form.parentNode.removeChild(form);
            })
            .catch((err) => {
                console.log(err);
                submitting = false;
                if (err.response && err.response.status === 400) {
                    const message = Object.values(err.response.data).join('\n');
                    alert(message);
                    return;
                }
                alert('Internal server error');
            });
    });
}

function initModal() {
    Micromodal.init();
    document.querySelector('.modal__close').addEventListener('click', () => {
        Micromodal.close('contact-modal');
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initScrollSpy();
    initModal();
    handleFormSubmit();
});
